<template>
<div>
    <page-title :heading="$t('accounting.lang_nav_collectivebill')" :subheading="$t('erp.lang_nav_faktura')" :icon=icon></page-title>

    <div class="app-main__inner">
      <v-card>
        <CollectiveBillList></CollectiveBillList>
      </v-card>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import CollectiveBillList from "@/components/billing/collectivebill/CollectiveBillList";

export default {
  components: {CollectiveBillList, PageTitle},

  data: () => ({
    icon: 'pe-7s-folder icon-gradient bg-tempting-azure',
  })
}
</script>
