<template>
  <v-container class="px-0 mx-0 pa-0" fluid>
    <v-row class="pa-0 ma-0">
      <!--start: filters-->

      <v-col class="pb-0 mb-0" cols="12" sm="12" md="12">
        <v-select
            :items="this.dateOptions"
            @change="getItems($event)"
            class="mx-auto"
            dense
            label=""
            item-text="name"
            item-value="id"
            outlined
            v-model="selectedDateOption"
        />
      </v-col>

      <v-col v-if="selectedDateOption === 9" class="mt-0 pt-0" cols="12" sm="6">
        <BaseDateInput
            dense
            :label="$t('generic.lang_from')"
            outlined
            type="date"
            @input="compareDateThenLoad"
            v-model="start"
        />
      </v-col>

      <v-col v-if="selectedDateOption === 9" class="mt-0 pt-0" cols="12" sm="6">
        <BaseDateInput
            dense
            :label="$t('generic.lang_till')"
            outlined
            type="date"
            @input="compareDateThenLoad"
            v-model="end"
        />
      </v-col>
      <!--end: filters-->

      <v-col cols="12" sm="5">
        <v-row class="fill-height pa-0 ma-0">
          <v-col class="pa-0 ma-0">
            <div style="height: 0 !important; visibility: hidden !important">
              <v-calendar
                  ref="calendar"
                  v-model="requested_date"
                  color="primary"
                  type="day"
              ></v-calendar>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="2" v-if="selectedItems.length > 0">
        <v-row class="fill-height pa-0 ma-0">
          <v-col class="pa-0 ma-0">
            <CollectiveBillDatatableDialog
                v-bind:start-date="getForm.startDate"
                v-bind:end-date="getForm.endDate"
                @reloadCollectiveDT="getItems"
                :customers="selectedItems"
                :date="requested_date"
                mode="multi"
            ></CollectiveBillDatatableDialog>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pa-0" cols="12">
        <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :no-data-text="$t('generic.lang_noEntriesFound')"
            v-model="selectedItems"
            show-select
            item-key="index"
        >
          <template v-slot:item.total="{ item }">
            {{ item.total | currency }}
          </template>

          <template
              v-slot:item.crud="{ item }"
              v-if="selectedItems.length == 0"
          >
            <v-btn @click="selectItem(item)" icon color="primary">
              <v-icon>remove_red_eye</v-icon>
            </v-btn>

            <!-- BUTTON TO CREATE INVOICES -->
            <CollectiveBillDatatableDialog
                v-bind:start-date="getForm.startDate"
                v-bind:end-date="getForm.endDate"
                @reloadCollectiveDT="getItems"
                :customer="item"
                mode="single"
            ></CollectiveBillDatatableDialog>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- date picker dialog -->
    <v-dialog v-model="picker" max-width="400px" scrollable>
      <v-card align="center" class="pa-0 ma-0">
        <v-card-title class="pa-0 px-0 ma-0">
          <v-spacer/>
          <v-btn icon @click="picker = false">
            <v-icon color="error"> close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-date-picker
              v-model="requested_date"
              event-color="green lighten-1"
              width="100%"
          ></v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
          v-if="touchKeyboard.visible"
          id="onScreenKeyboard"
          :accept="hideTouchKeyboard"
          :cancel="hideTouchKeyboard"
          :defaultKeySet="touchKeyboard.keySet"
          :input="touchKeyboard.input"
          :layout="touchKeyboard.layout"
          :options="touchKeyboard.options"
          class="internalWidthExpanded"
      />
    </div>
  </v-container>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import {ENDPOINTS} from "@/config";
import CollectiveBillDatatableDialog from "@/components/billing/collectivebill/CollectiveBillDatatableDialog";
import moment from "moment";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  name: "CollectiveBillList",
  components: {CollectiveBillDatatableDialog, BaseDateInput},
  mixins: [mixin],

  data() {
    return {
      picker: false,
      requested_date: "",
      loading: false,
      selectedItems: [],
      items: [],
      start: "",
      end: "",
      selectedDateOption: 1,
    };
  },
  computed: {
    getForm() {
      let form = {};
      let now = Math.floor(Date.now() / 1000);
      let cashierID = this.currentCashierID;

      if (this.selectedDateOption === 1)
        form = {
          startDate: moment.unix(now).startOf("days").unix(),
          endDate: now,
        };
      else if (this.selectedDateOption === 2)
        form = {
          startDate: moment
              .unix(now)
              .startOf("days")
              .subtract(1, "days")
              .unix(),
          endDate: moment
              .unix(now)
              .startOf("days")
              .subtract(1, "seconds")
              .unix(),
        };
      else if (this.selectedDateOption === 3)
        form = {
          startDate: this.getThisWeek(),
          endDate: now,
        };
      else if (this.selectedDateOption === 4)
        form = {
          startDate: this.getLastWeek(),
          endDate: moment.unix(this.getLastWeek()).endOf("weeks").unix(),
        };
      else if (this.selectedDateOption === 5)
        form = {
          startDate: this.getThisMonth(),
          endDate: now,
        };
      else if (this.selectedDateOption === 6) {
        form = {
          startDate: this.getLastMonth(),
          endDate: moment.unix(this.getLastMonth()).endOf("months").unix(),
        };
      } else if (this.selectedDateOption === 7)
        form = {
          startDate: this.getThisYear(),
          endDate: now,
        };
      else if (this.selectedDateOption === 8) {
        form = {
          startDate: this.getLastYear(),
          endDate: moment.unix(this.getLastYear()).endOf("years").unix(),
        };
      } else if (this.selectedDateOption === 9) {
        form = {
          startDate: this.getFrom(),
          endDate: this.getTo(),
        };
      }
      return form;
    },
    dateOptions() {
      return [
        {
          id: 1,
          name: this.$t("generic.lang_today"),
        },
        {
          id: 2,
          name: this.$t("generic.lang_yesterday"),
        },
        {
          id: 3,
          name: this.$t("generic.lang_thisWeek"),
        },
        {
          id: 4,
          name: this.$t("generic.lang_last_week"),
        },
        {
          id: 5,
          name: this.$t("generic.lang_this_month"),
        },
        {
          id: 6,
          name: this.$t("generic.lang_last_month"),
        },
        {
          id: 7,
          name: this.$t("generic.lang_this_year"),
        },
        {
          id: 8,
          name: this.$t("generic.lang_last_year"),
        },
        {
          id: 9,
          name: this.$t("generic.lang_from_to"),
        },
      ];
    },

    headers: function () {
      return [
        {
          text: this.$t('generic.lang_index'),
          value: "index",
          sortable: false,
        },
        {
          text: this.$t('generic.lang_customer'),
          value: "name",
          sortable: false,
        },
        {
          text: this.$t('accounting.lang_nav_lieferschein'),
          value: "sumDeliveryNotes",
          sortable: false,
        },
        {
          text: this.$t('generic.lang_total'),
          value: "total",
          sortable: false,
        },
        {
          text: "",
          value: "crud",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    requested_date() {
      this.getItems();
    },
  },

  mounted() {
    this.getItems();
  },

  methods: {
    /**
     * check if selected range is valid then load bills
     */
    compareDateThenLoad() {
      if (this.getTo() > this.getFrom()) {
        this.getItems();
      }
    },
    getFrom() {
      let startDate = new Date(this.start);
      return moment
          .unix(startDate.getTime() / 1000)
          .startOf("days")
          .unix();
    },
    getTo() {
      let endDate = new Date(this.end);
      return moment
          .unix(endDate.getTime() / 1000)
          .endOf("days")
          .unix();
    },
    getLastYear() {
      let current = moment.unix(Date.now() / 1000);

      return current.startOf("years").subtract(1, "years").unix();
    },
    getThisYear() {
      let current = moment.unix(Date.now() / 1000);

      return current.startOf("years").unix();
    },
    getLastMonth() {
      let current = moment.unix(Date.now() / 1000);
      return current.startOf("months").subtract(1, "months").unix();
    },
    getThisMonth() {
      let current = moment.unix(Date.now() / 1000);
      return current.startOf("months").unix();
    },
    getLastWeek() {
      let current = moment.unix(Date.now() / 1000);

      return current.startOf("weeks").subtract(1, "weeks").unix();
    },
    getThisWeek() {
      let current = moment.unix(Date.now() / 1000);
      return current.startOf("weeks").unix();
    },
    setToday() {
      let dt = new Date();
      this.requested_date =
          dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    getItems(dateOption = 0) {
      if (dateOption === 9) return;
      this.loading = true;
      this.items = [];

      this.axios
          .post(ENDPOINTS.BILLING.COLLECTIVEBILLS.GETDAY, this.getForm)
          .then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              this.items = res.data.deliveryNotes.map((item,index)=>{
                return{
                  index:index,
                  name:item.name,
                  sumDeliveryNotes:item.sumDeliveryNotes,
                  total:item.total,
                  customer_id: item.customer_id
                }
              })
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    selectItem(item) {
      this.$router.push({
        name: "billing.collectivebill.day",
        params: {
          startDate: this.getForm.startDate,
          endDate: this.getForm.endDate,
          customer_id: item.customer_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.date {
  display: block;
  width: 190px;
  height: 80px;
  margin: 5px 5px 8px 5px;
  background: #fff;
  text-align: center;
  font-family: "Helvetica", sans-serif;
  position: relative;
}

.date .binds {
  position: absolute;
  height: 15px;
  width: 150px;
  background: transparent;
  border: 2px solid #999;
  border-width: 0 5px;
  top: -6px;
  left: 0;
  right: 0;
  margin: auto;
}

.date .month {
  background: #555;
  display: block;
  padding: 8px 0;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid #333;
  box-shadow: inset 0 -1px 0 0 #666;
}

.date .day {
  display: block;
  margin: 0;
  padding: 5px 0;
  height: 35px;
  box-shadow: 0 0 3px #ccc;
  position: relative;
}

.date .day .time {
  font-size: 20px;
}

.date .day::after {
  content: "";
  display: block;
  height: 100%;
  width: 96%;
  position: absolute;
  top: 3px;
  left: 2%;
  z-index: -1;
  box-shadow: 0 0 3px #868484;
}

.date .day::before {
  content: "";
  display: block;
  height: 100%;
  width: 95%;
  position: absolute;
  top: 6px;
  left: 0%;
  z-index: -1;
  box-shadow: 0 0 3px #ccc;
}

.copy,
.actions {
  cursor: pointer;
}

.v-toolbar__content {
  padding: 0 !important;
}

#pdfcontainer {
  width: 102% !important;
  margin-left: -13px !important;
  padding: 5px !important;
  padding-top: 0 !important;
}
</style>
